import { authHeader } from './auth-header';
import axios from 'axios'
import router from '../../router/index'

export const userService = {
    login,
    logout,
    register,
    getAll,
};

function login(username, password) {

    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ email, password })
    // };
    var baseapi = localStorage.getItem("baseapi");
    console.log(username)
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            return axios.post(baseapi+'api/auth/signin', {
                username,
                password,
                headers: {
                "Content-type": "application/json",
                    }
                    }).
                    then(res => {
                        const user = res.data;
                        // console.log(user)
                        if (user.accessToken) {
                            // store user details and jwt token in local storage to keep user logged in between page refreshes
                            localStorage.setItem('user', JSON.stringify(user));
                            console.log(user)
                        }
                        router.push('/');
                        return user;
                    }).catch(err => {
                        reject('These credentials do not match our records.');
                        console.log(err)
                    return err;
                    });
                }, 500);
            });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
